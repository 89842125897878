<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      style="white-space: nowrap"
      :items-per-page="-1"
    >
      <template
        v-slot:[`item.${header.value}`]="{ item }"
        v-for="header in headers.filter((v) => v.hasSlot)"
      >
        <slot :name="`dt_${header.value}`" :item="item"></slot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "DataTable",

  props: {
    headers: Array,
    items: Array,
  },

  data() {
    return {};
  },
};
</script>
